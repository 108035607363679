<script>
import appConfig from "@/app.config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";
import { http } from "@/helpers/easyindustriaapi/config";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Atendimentos",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { List, Insert, Edit, PageHeader, Layout },
  data() {
    return {
      vet_convenios:[],
      vet_abordagens:[],
      vet_responsaveis:[],
      vet_tramentos:[],
      vet_pacientes:[],
      vet_profissionais:[],
      currentAtendimento:{},
      currentProfessional: {},
      professionals: [],
      inserindo: false,
      editando: false,
      titleBody: "Lista de Atendimentos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Atendimentos",
          href: "/atendimentos",
          active: true,
        },
      ],
      atendimentos:[],
    };
  },
  computed: {
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    this.getData();
  },
  methods: {
    async doPost(plano) {
      plano.empresa_id = this.currentEmpresa.id;
      let response = await http
        .post("/atendimento?empresa_id=" + this.currentEmpresa.id, plano)
        .catch((error) => {
          this.makeToast("danger", error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro incluído");
      }
    },
    async doPut(atendimento) {
      this.onLoader();
      atendimento.user_id = this.currentUser.id;
      let response = await http
        .put(
          "/atendimento/" + atendimento.id + "?empresa_id=" + this.currentEmpresa.id,
          atendimento
        )
        .catch((error) => {
          this.offLoader();
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro alterado");
      }
    },
    async doDelete(plano) {
      this.onLoader();
      plano.empresa_id = this.currentEmpresa.id;
      let response = await http
        .delete(
          "/atendimento/" + plano.id + "?empresa_id=" + this.currentEmpresa.id,
          plano
        )
        .catch((error) => {
          this.offLoader();
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("warning", "Registro excluído");
      }
    },
    getTable() {
      Swal.fire({
        title: "Aguarde",
        text: "Carregando tabelas...",
        allowOutsideClick: false,
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const promisse = http.get("/atendimento/tabelas?empresa_id=" + this.currentEmpresa.id);
      return promisse.then((response) => {
        if (response.status === 200) {
          this.vet_profissionais = response.data.professionals;
          this.vet_pacientes     = response.data.pacients;
          this.vet_tramentos     = response.data.specialtys;
          this.vet_abordagens    = response.data.approachs;
          this.vet_convenios     = response.data.agreements;
          Swal.close();
          return true;
        }
      }).catch((error) => {
        console.log(error.response.status);

        if (error.response.status != 403) {
          Swal.close();
          Swal.fire({
            icon: "warning",
            title: "Acesso negado",
            text: `Erro: ${error.response.data.message}\n.`,
          });
        }
      }).finally(() => {
      })
    },
    getData() {
      Swal.close();
      Swal.fire({
        title: "Aguarde",
        text: "Carregando Atendimentos...",
        allowOutsideClick: false,
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const promisse = http.get("/atendimento?empresa_id=" + this.currentEmpresa.id);
      return promisse.then((response) => {
        if (response.status === 200) {
          this.atendimentos = response.data;
          Swal.close();
          return this.atendimentos;
        }
      }).catch((error) => {
        console.log(error);
        this.makeToast("danger", error.response.data);
      }).finally(() => {
        Swal.close();
      })
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async insert() {
      await this.getTable();
      this.inserindo = !this.inserindo;
    },
    edit(value) {
      this.getTable();
      this.currentAtendimento = value;
      this.editando = !this.editando;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Incluir Atendimento
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>

          <div class="card-body" v-if="!isHide">
            <Insert
              v-if="inserindo"
              :currentEmpresa="currentEmpresa"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
              :vet_profissionais="vet_profissionais"
              :vet_pacientes="vet_pacientes"
              :vet_tramentos="vet_tramentos"
              :vet_responsaveis="vet_responsaveis"
              :vet_abordagens="vet_abordagens"
              :vet_convenios="vet_convenios"
            />
            <Edit
              v-if="editando"
              :currentEmpresa="currentEmpresa"
              :currentAtendimento="currentAtendimento"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              :vet_profissionais="vet_profissionais"
              :vet_pacientes="vet_pacientes"
              :vet_tramentos="vet_tramentos"
              :vet_responsaveis="vet_responsaveis"
              :vet_abordagens="vet_abordagens"
              :vet_convenios="vet_convenios"
            />
          </div>
          <List
            v-if="isHide"
            :currentEmpresa="currentEmpresa"
            :listResponsibles="atendimentos"
            :hide="isHide"
            @newTitle="changeHomeTitle"
            @edit="edit"
            @doDelete="doDelete"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>