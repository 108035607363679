<script>
console.clear();
import { notificationMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  props: {
    currentEmpresa: {
      type: Object,
      required: true,
    },
    vet_profissionais: { type: Array },
    vet_tramentos: { type: Array },
    vet_responsaveis: { type: Array },
    vet_abordagens: { type: Array },
    vet_convenios: { type: Array },
    vet_pacientes: { type: Array },
  },
  components: {Multiselect},
  data() {
    return {
      convenios:[],
      responsaveis:[],
      abordagens:[],
      tratamentos:[],
      pacientes: [],
      pacient: {},
      profissionais: [],
      loader: {
        get: false,
      },
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Atendimentos",
          href: "/atendimentos",
          active: true,
        },
      ],
      titleBody: "Inserindo",
      inserindo: true,
      submitted: false,
      service: {
        pacient: null,
        pacient_id: null,
        professional: null,
        agreement_id: null, // convêcnio (Unimed, SulAmerica)
        service_value: null,
        start_date: [],
        hour: [],
        responsible_id: null,
        contact: null,
        treatment: null,
        approach: null,
        local: null,
      },
      listRecurrence: [
        { id: 1, type: "none", name: "Nunca" },
        // { value: "daily", text: "Diariamente" },
        { id: 2, type: "weekly", name: "Semanalmente" },
        // { value: "monthly", text: "Mensalmente" },
      ]
    };
  },
  created() {
    this.profissionais = this.vet_profissionais;
    this.pacientes = this.vet_pacientes;
    this.tratamentos = this.vet_tramentos;
    this.responsaveis = this.vet_responsaveis
    this.abordagens = this.vet_abordagens
    this.convenios     = this.vet_convenios
  },
  validations() {
    return {
      service: {
        service_value: { required },
        treatment_id: { required },
        approach_id: { required },
        agreement_id: { required },
        pacient_id: { required },
        professional_id: { required },
        start_date: { required },
        start_time: { required },
        responsible_id: { required },
      },
    };
  },
  mounted() {
    this.$emit("newTitle", "Incluir Atendimento");
    this.pacient.empresa_id = this.currentEmpresa.id;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    getCidadeId(objects, key) {
      return objects.map(function (object) {
        return object[key];
      });
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    seleciona_paciente(event) {
      this.service.responsible_id = event.responsible.id;
      this.service.pacient_id = event.id;
      this.service.pacient = event;
    },
    seleciona_prof(event) {
      this.service.professional_id = event.id;
      this.service.professional = event;
      this.tratamentos = event.specialty;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.setPost();
    },
    setPost() {
      this.service.service_value = this.new_convert_srting_float(this.service.service_value)
      this.$emit(
        "doPost",
        {
          service_value: this.service.service_value,
          contact: this.service.contact,
          local: this.service.local,
          treatment_id: this.service.treatment_id,
          approach_id: this.service.approach_id,
          responsible_id: this.service.responsible_id,
          agreement_id: this.service.agreement_id,
          pacient_id: this.service.pacient_id,
          professional_id: this.service.professional_id,
          empresa_id: this.currentEmpresa.id,
          start_date: this.service.start_date,
          end_date: this.service.end_date,
          start_time: this.service.start_time,
          end_time: this.service.end_time,
          recurrence: this.service.recurrence,
          specialty_id: this.service.specialty_id,
        }
      );
    },
    back() {
      this.$emit("back");
    },
    changeLoader(value) {
      this.loader.get = value;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formAtendimento">
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="nome">Paciente:</label>
            <Multiselect
              id="selectPacient"
              v-model="service.pacient"
              @select="seleciona_paciente($event)"
              :options="pacientes"
              label="nome"
              track-by="id"
              deselect-label="ENTER para remover"
              select-label="Enter para selecionar"
              placeholder="Selecione"
            >
              <template #option="props">
                {{ props.option.id }} - {{ props.option.nome }}
              </template>
            </Multiselect>
          </div>

          <div class="form-group col-md-4">
            <label for="selectAtendimentoProfessional">Profissional</label>
            <Multiselect
              id="selectPacient"
              v-model="service.professional"
              @select="seleciona_prof($event)"
              :options="profissionais"
              label="name"
              track-by="id"
              deselect-label="ENTER para remover"
              select-label="Enter para selecionar"
              placeholder="Selecione"
            >
               <template #option="props">
                  {{ props.option.id }} - {{ props.option.name }}
              </template>
            </Multiselect>
          </div>

          <div class="form-group col-md-4">
            <label for="service_value">Valor do atendimento:</label>
            <input
              id="service_value"
              type="text"
              class="form-control"
              placeholder="Ex: 70"
              v-model="service.service_value"
              @input="formatInputFloat($event,service,'service_value')"
            />
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <h4 class="card-title col-md-12">Período recorrência</h4>
          <p class="col-md-12">Defina o período de recorrência do Atendimento</p>
          <div class="form-group col-md-2">
            <label for="end_hour">Tipo de recorrência</label>
            <select
              class="custom-select mr-sm-2"
              id="selectRecurrence"
              v-model="service.recurrence"
            >
              <option v-for="recurrence in listRecurrence" :key="recurrence.id" value="recurrence.type">{{ recurrence.name }}</option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label for="start_date">Data início:</label>
            <input
              name="start_date"
              type="date"
              class="form-control"
              required
              v-model="service.start_date"
            />
          </div>
          <div class="form-group col-md-2">
            <label for="start_time">Hora início:</label>
            <input
              name="start_time"
              type="time"
              class="form-control"
              required
              v-model="service.start_time"
            />
          </div>
          <div class="form-group col-md-2">
            <label for="end_time">Hora fim:</label>
            <input
              name="end_time"
              type="time"
              class="form-control"
              v-model="service.end_time"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="end_date">Data fim:</label>
            <input
              name="end_date"
              type="date"
              class="form-control"
              v-model="service.end_date"
            />
          </div>
        </div>
          
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-6">
            <label for="selectAtendimentoTreatment">Tratamento</label>
            <select
              class="custom-select mr-sm-2"
              id="selectTreatment"
              v-model="service.treatment_id"
            >
            <!--  -->
              <option value="" selected="selected">
                Selecione o tratamento
              </option>
              <option
                v-for="(item, index) in tratamentos"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-6">
            <label for="selectAtendimentoApproach">Abordagem</label>
            <select
              class="custom-select mr-sm-2"
              id="selectApproach"
              v-model="service.approach_id"
            >
              <option value="-1" selected="selected">
                Selecione a abordagem
              </option>
              <option
                v-for="(item, index) in abordagens"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-6">
            <label for="agreement_id">Convênio:</label>
            <select
              v-model="service.agreement_id"
              class="form-control"
              id="agreement_id"
            >
              <option value="" selected="selected">
                Selecione o convênio
              </option>
              <option
                v-for="agreement in convenios"
                :key="agreement.id"
                :value="agreement.id"
              >
                {{ agreement.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-6">
            <label for="localAtendimento">Local do atendimento:</label>
            <input
              id="localatendimento"
              type="text"
              class="form-control"
              placeholder="Local de atendimento"
              v-model="service.local"
            />
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-right: 20px"
            @click.prevent="setPost()"
          >
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>