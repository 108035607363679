<script>
export default {
  props: {
    atendimento: { type: Object },
  },
  data() {
    return {
      fieldsAgendamentos: [
        {
          label: 'Título',
          key: 'title',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Início',
          key: 'start',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: "formatDateTimeBR"
        },
        {
          label: 'Fim',
          key: 'end',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: "formatDateTimeBR"
        },
        {
          label: 'Parecer',
          key: 'desc',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ]
    }
  },
  
}
</script>

<template>
<div class="atendimento">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div role="tablist">
            <!-- Card Paciente -->
            <b-card no-body class="mb-1 shadow-none">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-1
                    class="text-dark"
                    href="javascript: void(0);"
                  >Paciente</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="atendimento-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-md-5 col-sm-12 border-detail m-2 p-2">Nome: <strong>{{ atendimento.pacient.nome  }}</strong></div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 col-sm-12 border-detail m-2 p-2">Nome da Mãe: <strong>{{ atendimento.pacient.mae  }}</strong></div>
                        <div class="col-md-6 col-sm-12 border-detail m-2 p-2">Nome do Pai: <strong>{{ atendimento.pacient.pai  }}</strong></div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-sm-12 border-detail m-2 p-2">C. P. F.: <strong>{{ atendimento.pacient.cpf  }}</strong></div>
                        <div class="col-md-4 col-sm-12 border-detail m-2 p-2">Contato: <stron>{{ atendimento.pacient.contact }}</stron></div>
                        <div class="col-md-2 col-sm-12 border-detail m-2 p-2">
                          É particular?: <b-badge variant="success" v-if="atendimento.pacient.is_private == 1">Sim</b-badge>
                          <b-badge variant="danger" v-else>Não</b-badge>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
            </b-card>

            <!-- Card Profissional -->
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Profissional</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="atendimento-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-12">
                        Nome: {{ atendimento.professional ? atendimento.professional.name : '' }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        E-mail {{ atendimento.professional ? atendimento.professional.email : '' }}
                      </div>
                      <div class="col-md-4">
                        Contato: {{ atendimento.professional ? atendimento.professional.contact : '' }}
                      </div>
                      <div class="col-md-2">
                        Conselho: {{ atendimento.professional ? atendimento.professional.council : '' }}
                      </div>
                      <div class="col-md-2">
                        Registro: {{ atendimento.professional ? atendimento.professional.council : '' }}
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
            </b-card>
            <!-- Card Tratamento -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-3
                    class="text-dark"
                    href="javascript: void(0);"
                  >Tratamento</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="atendimento-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text><p>Tratamento: {{ atendimento.specialty ? atendimento.specialty.name : '' }}</p></b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- Card Abordagem -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-4
                    class="text-dark"
                    href="javascript: void(0);"
                  >Abordagem</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-4" accordion="atendimento-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <p>Abordagens: <b-badge data-name="approach" class="field-approach" variant="info">{{ atendimento.approach ? atendimento.approach.name : '' }}</b-badge></p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- Card Agendamentos -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-5
                    class="text-dark"
                    href="javascript: void(0);"
                  >Agendamentos</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="atendimento-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <b-table
                      :fields="fieldsAgendamentos"
                      striped
                      hover
                      :items="atendimento.agendas"
                    >
                      <template v-slot:cell(title)="row">
                        {{ truncate(row.item.title, 50) }}
                      </template>
                    </b-table>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ formatDateTimeBR(atendimento.created_at) }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ formatDateTimeBR(atendimento.updated_at) }}</b-badge></p>
</div>
</template>

<style scoped>
  .border-detail {
    border-right: 1px solid #D5D6D7 !important;
  }
</style>