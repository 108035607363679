<script>
// console.clear();

import { notificationMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
// import Layout from "@/views/layouts/main";
// import PageHeader from "@/components/page-header";
export default {
  props: {
    currentEmpresa: {
      type: Object,
      required: true,
    },
    currentAtendimento: { type: Object },
    // listAgreements: { required: true },
    // listResponsibles: { required: true },
    vet_profissionais: { type: Array },
    vet_tramentos: { type: Array },
    vet_responsaveis: { type: Array },
    vet_abordagens: { type: Array },
    vet_convenios: { type: Array },
    vet_pacientes: { type: Object },
  },
  components: {},
  data() {
    return {
      convenios: [],
      responsaveis: [],
      abordagens: [],
      tratamentos: [],
      pacientes: [],
      profissionais: [],
      loader: {
        get: false,
      },
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Atendimentos",
          href: "/atendimentos",
          active: true,
        },
      ],
      titleBody: "Alterando",
      inserindo: true,
      submitted: false,
      service: {
        pacient: "",
        professional: "",
        agreement_id: -1, // convêcnio (Unimed, SulAmerica)
        service_value: "",
        day: [],
        hour: [],
        responsable_id: -1,
        contact: "",
        treatment: "",
        approach: "",
        local: "",
      },
    };
  },
  created() {
    this.profissionais = this.vet_profissionais;
    this.pacientes = this.vet_pacientes.pacients.data;
    this.tratamentos = this.vet_tramentos;
    this.responsaveis = this.vet_responsaveis;
    this.abordagens = this.vet_abordagens;
    this.convenios = this.vet_convenios;
    this.service = this.currentAtendimento;
  },
  validations() {
    return {
      service: {
        pacient: { required },
        professional: { required },
        agreement_id: { required },
        service_value: { required },
        day: { required },
        hour: { required },
        responsable_id: { required },
        contact: { required },
        treatment: { required },
        approach: { required },
        local: { required },
      },
    };
  },
  mounted() {
    this.$emit("newTitle", "Alterando Atendimento");
    // this.listPaises = [this.paisDefault];
    //this.pacient.pais_id = 1;
    this.pacient.empresa_id = this.currentEmpresa.id;
    // this.listUFs = listEstados;
    //this.getCidadesStart(29);
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    getCidadeId(objects, key) {
      return objects.map(function (object) {
        return object[key];
      });
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    setPut() {
      this.service.service_value = this.new_convert_srting_float(
        this.service.service_value
      );
      this.$emit("doPut", this.service);
    },
    seleciona_paciente(event) {
      console.log(event);
    },
    seleciona_prof(event) {
      console.log(event);
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      // // stop here if form is invalid
      // this.$v.$touch();
      // if (this.$v.pacient.$error) {
      //   this.makeToast("danger", "Erro de validação!");
      // } else {
      //   this.setPost();
      // }

      this.setPost();
    },
    setPost() {
      this.service.service_value = this.new_convert_srting_float(
        this.service.service_value
      );
      this.$emit("doPost", this.service);
    },
    back() {
      this.$emit("back");
    },
    changeLoader(value) {
      this.loader.get = value;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formAtendimento">
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="nome">Paciente:</label>
            <select
              @change="seleciona_paciente($event)"
              class="custom-select mr-sm-2"
              id="selectProfessional"
              v-model="service.pacient_id"
            >
              <option value="-1" selected="selected">
                Selecione o Paciente
              </option>
              <option
                v-for="(item, index) in pacientes"
                :key="index"
                :value="item.id"
              >
                {{ item.nome }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="selectAtendimentoProfessional">Profissional</label>
            <select
              @change="seleciona_prof($event)"
              class="custom-select mr-sm-2"
              id="selectProfessional"
              v-model="service.professional_id"
            >
              <option value="-1" selected="selected">
                Selecione o profissional
              </option>
              <option
                v-for="(item, index) in profissionais"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="service_value">Valor do atendimento:</label>
            <input
              id="service_value"
              type="text"
              class="form-control"
              placeholder="Ex: 70"
              v-model="service.service_value"
              @input="formatInputFloat($event, service, 'service_value')"
            />
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-2">
            <label for="dayHour">Dia:</label>
            <input
              type="date"
              class="form-control"
              required
              v-model="service.day"
            />
          </div>

          <div class="form-group col-md-2">
            <label for="Hour">Hora:</label>
            <input
              type="time"
              class="form-control"
              required
              v-model="service.hour"
            />
          </div>

          <div class="form-group col-md-4">
            <label for="selectAtendimentoTreatment">Tratamento</label>
            <select
              class="custom-select mr-sm-2"
              id="selectTreatment"
              v-model="service.treatment_id"
            >
              <!--  -->
              <option value="-1" selected="selected">
                Selecione o tratamento
              </option>
              <option
                v-for="(item, index) in tratamentos"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="selectAtendimentoApproach">Abordagem</label>
            <select
              class="custom-select mr-sm-2"
              id="selectApproach"
              v-model="service.approach_id"
            >
              <option value="-1" selected="selected">
                Selecione a abordagem
              </option>
              <option
                v-for="(item, index) in abordagens"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="responsable_id">Nome do responsável:</label>
            <select
              v-model="service.responsible_id"
              class="form-control"
              id="responsible_id"
            >
              <option value="-1" selected="selected">
                Selecione o Responsável
              </option>
              <option
                v-for="(item, index) in responsaveis"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="agreement_id">Convênio:</label>
            <select
              v-model="service.agreement_id"
              class="form-control"
              id="agreement_id"
            >
              <option value="-1" selected="selected">
                Selecione o convênio
              </option>
              <option
                v-for="agreement in convenios"
                :key="agreement.id"
                :value="agreement.id"
              >
                {{ agreement.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="localAtendimento">Local do atendimento:</label>
            <input
              id="localatendimento"
              type="text"
              class="form-control"
              placeholder="Local de atendimento"
              v-model="service.local"
            />
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-right: 20px"
            @click.prevent="setPut()"
          >
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>